/*!
 * b4st v3.0 | Unicence
 * http://simonpadbury.github.io/Codebase/
 */
.author-bio,
.comments-wrap {
  margin: 36px auto;
  max-width: 720px;
  padding-left: 16px;
  padding-right: 16px;
}

.site-header-image {
  display: block;
  margin: auto;
}

.alignleft {
  float: left;
}

.alignright {
  float: right;
}

.aligncenter {
  clear: both;
  display: block;
}

/* Author bio */
.author-bio .avatar {
  border: 1px solid #dee2e6;
  border-radius: 50%;
}

/* Main content */
/* Sidebar */
/***********************/
/*--------------------------------------------------------------
## Cover
--------------------------------------------------------------*/
.wp-block-cover-text p {
  padding: 1.5em 16px;
}

@media screen and (min-width: 768px) {
  .wp-block-cover-text p {
    padding: 1.5em 0;
  }
}

/*--------------------------------------------------------------
## Latest Posts
--------------------------------------------------------------*/
ul.wp-block-latest-posts.alignwide,
ul.wp-block-latest-posts.alignfull,
ul.wp-block-latest-posts.is-grid.alignwide,
ul.wp-block-latest-posts.is-grid.alignwide {
  padding: 0 16px;
}

@media screen and (min-width: 768px) {
  .entry-header,
  .entry-content {
    font-size: calc(1rem + (1.375 - 1) * ((100vw - 768px) / (1200 - 768)));
    font-weight: 300;
    line-height: inherit;
  }
  .entry-header h1 {
    font-size: calc(2.5rem + (3.4375 - 2.5) * ((100vw - 768px) / (1200 - 768)));
  }
}

@media screen and (min-width: 1200px) {
  .entry-header,
  .entry-content {
    font-size: 1.375rem;
  }
  .entry-header h1 {
    font-size: 3.4375rem;
  }
}

.more-link {
  display: block;
}

.wp-block-code {
  padding: 0.5em 1em;
  margin: 0 0 1.5rem;
}

.entry-content ul:not(.blocks-gallery-grid),
.entry-content ol {
  margin: 0 auto 1.5em auto;
  max-width: 720px;
  list-style-position: outside;
}

.entry-content li {
  margin-left: 2.5em;
}

.entry-content ul ul,
.entry-content ol ol,
.entry-content ul ol,
.entry-content ol ul {
  margin: 0 auto;
}

.entry-content ul ul li,
.entry-content ol ol li,
.entry-content ul ol li,
.entry-content ol ul li {
  margin-left: 0;
}

.wp-block-quote {
  margin-left: 16px;
  margin-right: 16px;
  padding: 0;
}

.wp-block-quote > * {
  margin-left: 1rem;
}

.wp-block-quote.is-large,
.wp-block-quote.is-style-large {
  margin: 36px auto;
  padding: 0;
}

.wp-block-quote.is-large cite,
.wp-block-quote.is-large footer,
.wp-block-quote.is-style-large cite,
.wp-block-quote.is-style-large footer {
  font-size: 13px;
  font-size: 0.8125rem;
}

@media screen and (min-width: 768px) {
  .wp-block-quote {
    margin: 48px auto;
  }
}

.wp-block-pullquote > p:first-child {
  margin-top: 0;
}

.wp-block-pullquote.alignright {
  margin-left: 2rem;
}

.wp-block-pullquote.alignleft {
  margin-right: 2rem;
}

.entry-content {
  margin: 48px auto;
  max-width: 720px;
  padding-left: 16px;
  padding-right: 16px;
}

.entry-content > .alignwide,
.wp-block-image.alignwide,
.wp-block-gallery.alignwide {
  width: 100vw;
  max-width: 1100px;
  margin: 1.5em 0 1.5em 50%;
  transform: translateX(-50%);
}

.entry-content > .alignwide > img {
  width: 1100px;
}

.entry-content > .alignfull,
.wp-block-image.alignfull,
.wp-block-gallery.alignfull {
  width: 100vw;
  max-width: 100vw;
  margin: 1.5em 0 1.5em 50%;
  transform: translateX(-50%);
}

.entry-content > .alignfull > img {
  width: 100vw;
}

@media screen and (min-width: 768px) {
  .entry-content > * {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.wp-block-columns {
  padding-right: 1rem;
  padding-left: 1rem;
}

.wp-block-separator {
  margin: 3em auto;
  padding: 0;
}

.wp-block-group > .wp-block-group__inner-container > * {
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;
}

.wp-block-group > .wp-block-group__inner-container > .alignwide {
  width: 100vw;
  max-width: 1100px;
  margin: 1.5em 0 1.5em 50%;
  transform: translateX(-50%);
}

.wp-block-group > .wp-block-group__inner-container > .alignfull {
  width: 100vw;
  max-width: 100vw;
  margin: 1.5em 0 1.5em 50%;
  transform: translateX(-50%);
}

.wp-block-group.has-background > .wp-block-group__inner-container > :first-child {
  margin-top: 0;
}

.wp-block-group.has-background > .wp-block-group__inner-container > .alignfull {
  width: calc( 100% + 60px);
  max-width: calc( 100% + 60px);
  position: relative;
  left: -30px;
}

img,
.size-auto,
.size-full,
.size-large,
.size-medium,
.size-thumbnail {
  max-width: 100%;
  height: auto;
}

.wp-block-image img {
  display: block;
}

.wp-block-image.alignleft,
.wp-block-image.alignright {
  width: 100%;
}

.wp-block-image.alignfull img {
  width: 100vw;
}

.wp-block-image .alignleft img,
.wp-block-image .alignright img,
.wp-block-image .alignleft figcaption,
.wp-block-image .alignright figcaption {
  max-width: 100%;
  width: 100%;
}

.wp-block-image .alignfull,
.wp-block-gallery.alignfull {
  width: 100vw;
}

.wp-block-image .alignright,
.wp-block-image .alignleft,
.wp-block-gallery.alignright,
.wp-block-gallery.alignleft {
  max-width: calc((720px / 2) - 16px);
}

.wp-block-image .alignright,
.wp-block-gallery.alignright {
  margin-left: 2rem;
}

.wp-block-image .alignleft,
.wp-block-gallery.alignleft {
  margin-right: 2rem;
}

.wp-block-embed.type-video > .wp-block-embed__wrapper {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%;
}

.wp-block-embed.type-video > .wp-block-embed__wrapper > iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.wp-block-video video {
  max-width: 720px;
}

.wp-block-table {
  overflow-x: auto;
  margin: 1.5rem0;
}

.wp-block-table table {
  border-collapse: collapse;
}

.wp-block-table td, .wp-block-table th {
  padding: .5em;
}

#comments,
#reply-title {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

.commentlist,
.commentlist ul {
  padding-left: 0;
}

.commentlist li {
  padding: 1rem;
}

.comment-meta {
  margin-bottom: 1rem;
}

.comment > .children {
  margin-top: 1rem;
  margin-right: -0.625rem;
  margin-bottom: -1.625rem;
}

body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

main {
  flex: 1 0 auto;
}

#site-footer {
  background-color: #f3f4f5;
}

@font-face {
  font-family: 'Gilroy-Bold';
  src: url("../../fonts/Gilroy-Bold.ttf");
}

@font-face {
  font-family: 'Gilroy-Heavy';
  src: url("../../fonts/Gilroy-Bold.ttf");
}

body,
#site-footer {
  font-family: 'Gilroy-Bold';
  background: #000000;
  color: #FFFFFF;
}

a {
  color: #FFFFFF;
}

a:hover {
  color: #FFFFFF;
  text-decoration: underline solid #FFFFFF 2px;
}

.navbar-brand img {
  max-width: 250px;
}

h1 {
  font-size: 50px;
}

header h2 {
  font-size: 1.5rem;
}

section h2:before {
  content: '';
  display: block;
  width: 40%;
  max-width: 175px;
  min-height: 6px;
  margin-bottom: 20px;
  background-color: #FFFFFF;
}

section h2.grey-bg {
  background: rgba(35, 35, 35, 0.75);
}

h4.uppercase {
  font-family: 'Gilroy-Heavy';
  text-transform: uppercase;
  font-size: 16px;
}

.gform_body button,
.btn {
  background: linear-gradient(to right, #ED4A29, #FF0027, #ED4A29, #FF0027);
  color: #FFFFFF;
  text-transform: uppercase;
  line-height: 2.5rem;
  border-radius: 2.5rem;
  padding: 0 2rem;
  border: none;
  background-size: 300% 100%;
  transition: all .4s ease-in-out;
}

.gform_body button:hover,
.btn:hover {
  color: #FFFFFF;
  background-position: 100% 0;
  transition: all .4s ease-in-out;
}

.gform_body button:focus,
.btn:focus {
  outline: none;
}

button:focus {
  outline: none;
}

#field_1_13 button {
  display: block;
  margin: 0 auto;
}

.navbar-toggler {
  border: 1px solid #FFFFFF;
  border-radius: 0;
}

.navbar-toggler:focus {
  outline: none;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

header.header-tall {
  background-size: cover !important;
}

.grey-widget {
  background: #232323;
}

.grey-widget-right {
  width: 50%;
  position: absolute;
  bottom: 0;
  right: 0;
  background: #232323;
}

.grey-widget-left {
  width: 50%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #232323;
}

.grey-widget-header {
  width: 275px;
  position: absolute;
  bottom: 0;
  right: 0;
  background: #000000;
  margin-right: 15px;
}

#middle-section {
  background-size: cover !important;
  min-height: 800px;
}

.line {
  height: 6px;
  background: #FFFFFF;
}

.corner {
  position: absolute;
  bottom: 0;
  right: 0;
  background: url("../../images/corner.svg");
  width: 38px;
  height: 44px;
}

.quote img {
  width: 100px;
  height: auto;
}

.quote p {
  font-size: 30px;
}

.range-title {
  font-size: 3rem;
  line-height: 3rem;
  border-right: 5px solid #FFFFFF;
}

#about-us-top {
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 800px;
}

#about-us-middle {
  background-size: cover !important;
  background-position: center center !important;
}

body.archive header,
body.single header,
body.page header {
  height: 450px;
  background-size: cover !important;
  background-position: center center;
}

.slider {
  color: #000000;
}

.slider-inner {
  border-radius: 25px;
}

.slider-inner img {
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

.form-control,
.form-control:focus {
  background: #5C5C5C;
  border: none;
  border-radius: 20px;
  color: #FFFFFF;
  padding: 8px 15px 6px 15px !important;
}

.form-control:focus {
  box-shadow: none;
}

span.gfield_required_text {
  display: none !important;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #FFFFFF !important;
  font-family: 'Gilroy-Heavy';
  text-transform: uppercase;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #FFFFFF !important;
  font-family: 'Gilroy-Heavy';
  text-transform: uppercase;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: #FFFFFF !important;
  font-family: 'Gilroy-Heavy';
  text-transform: uppercase;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: #FFFFFF !important;
  font-family: 'Gilroy-Heavy';
  text-transform: uppercase;
}

.gfield_radio label {
  margin-right: 1rem;
}

.gfield_radio img {
  min-width: 50px;
  opacity: 0.3;
}

.gfield_radio .gchoice {
  display: inline-block;
}

[type=radio] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

[type=radio]:checked + label img {
  opacity: 1;
}

#gfield_description_1_11 {
  padding: 0;
}

[type=checkbox] {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

[type=checkbox] + label {
  line-height: 1;
}

.menu-footer-container ul {
  padding-left: 0;
}

.menu-footer-container li {
  list-style: none;
}

.order-cta p.small {
  font-size: 2rem;
  line-height: 2rem;
}

.order-cta p.large {
  font-size: 3rem;
  line-height: 3rem;
  border-left: 5px solid #FFFFFF;
  padding-left: 1rem;
}

@media (min-width: 768px) {
  h1 {
    font-size: 105px;
  }
  .navbar-brand img {
    max-width: 100%;
  }
  .navbar-expand-md .navbar-nav .menu-item {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    position: relative;
    padding-left: 0;
    padding-right: 0;
  }
  .navbar-expand-md .navbar-nav .nav-link:after {
    content: '';
    display: block;
    position: absolute;
    background-color: #FFF;
    width: 100%;
    height: 3px;
    left: 0;
    bottom: 5px;
    transform: translateY(5px);
    opacity: 0;
  }
  .navbar-expand-md .navbar-nav .nav-link:hover:after {
    opacity: 1;
    transform: translateY(0);
  }
  .navbar-expand-md .navbar-nav .nav-link:after {
    transition: all .2s ease;
  }
  #title {
    padding-top: 25rem;
  }
  header.header-tall {
    min-height: 800px;
  }
  .range-title {
    font-size: 6rem;
    line-height: 6rem;
  }
  #site-footer {
    background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, black 30%), url("../../images/footer-bg.jpg");
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: auto 350px;
  }
  .order-cta p.small {
    font-size: 2.5rem;
    line-height: 2.5rem;
  }
  .order-cta p.large {
    font-size: 4.5rem;
    line-height: 4.5rem;
  }
}
