/*!
 * b4st v3.0 | Unicence
 * http://simonpadbury.github.io/Codebase/
 */

@import "b4st/base";
@import "b4st/typography";
@import "b4st/code";
@import "b4st/lists";
@import "b4st/quotes";
@import "b4st/layout";
@import "b4st/groups";
@import "b4st/images-and-galleries";
@import "b4st/embeds";
@import "b4st/tables";
@import "b4st/comments";
@import "b4st/footer";

@font-face {
  font-family: 'Gilroy-Bold';
  src: url('../../fonts/Gilroy-Bold.ttf');
}

@font-face {
  font-family: 'Gilroy-Heavy';
  src: url('../../fonts/Gilroy-Bold.ttf');
}

$black: #000000;
$charcoal: #232323;
$grey: #5C5C5C;
$white: #FFFFFF;
$fire-amber: #ED4A29;
$fire-red: #FF0027;

body,
#site-footer {
  font-family: 'Gilroy-Bold';
  background: $black;
  color: $white;
}

a {
  color: $white;

  &:hover {
    color: $white;
    text-decoration: underline solid $white 2px;
  }
}

.navbar-brand img {
  max-width: 250px;
}

h1 {
  font-size: 50px;
}

header h2 {
  font-size: 1.5rem;
}

section h2 {
  &:before {
    content: '';
    display: block;
    width: 40%;
    max-width: 175px;
    min-height: 6px;
    margin-bottom: 20px;
    background-color: $white;
  }

  &.grey-bg {
    background: rgba(35, 35, 35, 0.75);
  }
}

h4.uppercase {
  font-family: 'Gilroy-Heavy';
  text-transform: uppercase;
  font-size: 16px;
}

.gform_body button,
.btn {
  background: linear-gradient(to right, #ED4A29, #FF0027, #ED4A29, #FF0027);
  color: $white;
  text-transform: uppercase;
  line-height: 2.5rem;
  border-radius: 2.5rem;
  padding: 0 2rem;
  border: none;
  background-size: 300% 100%;
  transition: all .4s ease-in-out;

  &:hover {
    color: $white;
    background-position: 100% 0;
    transition: all .4s ease-in-out;
  }

  &:focus {
    outline: none;
  }
}

button {
  &:focus {
    outline: none;
  }
}

#field_1_13 button {
  display: block;
  margin: 0 auto;
}

.navbar-toggler {
  border: 1px solid $white;
  border-radius: 0;

  &:focus {
    outline: none;
  }
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

header.header-tall {
  background-size: cover !important;
}

.grey-widget {
  background: $charcoal;
}

.grey-widget-right {
  width: 50%;
  position: absolute;
  bottom: 0;
  right: 0;
  background: $charcoal;
}

.grey-widget-left {
  width: 50%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: $charcoal;
}

.grey-widget-header {
  width: 275px;
  position: absolute;
  bottom: 0;
  right: 0;
  background: $black;
  margin-right: 15px;
}

#middle-section {
  background-size: cover !important;
  min-height: 800px;
}

.line {
  height: 6px;
  background: $white;
}

.corner {
  position: absolute;
  bottom: 0;
  right: 0;
  background: url('../../images/corner.svg');
  width: 38px;
  height: 44px;
}

.quote {
  img {
    width: 100px;
    height: auto;
  }

  p {
    font-size: 30px;
  }
}

.range-title {
  font-size: 3rem;
  line-height: 3rem;
  border-right: 5px solid $white;
}

#about-us-top {
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 800px;
}

#about-us-middle {
  background-size: cover !important;
  background-position: center center !important;
}

body.archive,
body.single,
body.page {
  header {
    height: 450px;
    background-size: cover !important;
    background-position: center center;
  }
}

.slider {
  color: $black;
}

.slider-inner {
  border-radius: 25px;

  img {
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
  }
}

.form-control,
.form-control:focus {
  background: $grey;
  border: none;
  border-radius: 20px;
  color: $white;
  padding: 8px 15px 6px 15px !important;
}

.form-control:focus {
  box-shadow: none;
}

span.gfield_required_text {
  display: none !important;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: $white !important;
  font-family: 'Gilroy-Heavy';
  text-transform: uppercase;
}
::-moz-placeholder { /* Firefox 19+ */
  color: $white !important;
  font-family: 'Gilroy-Heavy';
  text-transform: uppercase;
}
:-ms-input-placeholder { /* IE 10+ */
  color: $white !important;
  font-family: 'Gilroy-Heavy';
  text-transform: uppercase;
}
:-moz-placeholder { /* Firefox 18- */
  color: $white !important;
  font-family: 'Gilroy-Heavy';
  text-transform: uppercase;
}

.gfield_radio {
  label {
    margin-right: 1rem;
  }

  img {
    min-width: 50px;
    opacity: 0.3;
  }

  .gchoice {
    display: inline-block;
  }
}

[type=radio] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

[type=radio]:checked + label img {
  opacity: 1;
}

#gfield_description_1_11 {
  padding: 0;
}

[type=checkbox] {
  width: 20px;
  height: 20px;
  margin-right: 5px;

  + label {
    line-height: 1;
  }
}

.menu-footer-container {
  ul {
    padding-left: 0;
  }

  li {
    list-style: none;
  }
}

.order-cta {
  p.small {
    font-size: 2rem;
    line-height: 2rem;
  }

  p.large {
    font-size: 3rem;
    line-height: 3rem;
    border-left: 5px solid $white;
    padding-left: 1rem;
  }
}

@media (min-width: 768px) {
  h1 {
    font-size: 105px;
  }

  .navbar-brand img {
    max-width: 100%;
  }

  .navbar-expand-md .navbar-nav .menu-item {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    position: relative;
    padding-left: 0;
    padding-right: 0;
  }

  .navbar-expand-md .navbar-nav .nav-link:after {
    content: '';
    display: block;
    position: absolute;
    background-color: #FFF;
    width: 100%;
    height: 3px;
    left: 0;
    bottom: 5px;
    transform: translateY(5px);
    opacity: 0;
  }

  .navbar-expand-md .navbar-nav .nav-link:hover:after {
    opacity: 1;
    transform: translateY(0);
  }

  .navbar-expand-md .navbar-nav .nav-link:after {
    transition: all .2s ease;
  }

  #title {
    padding-top: 25rem;
  }

  header.header-tall {
    min-height: 800px;
  }

  .range-title {
    font-size: 6rem;
    line-height: 6rem;
  }

  #site-footer {
    background: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(0,0,0,1) 30%), url('../../images/footer-bg.jpg');
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: auto 350px;
  }

  .order-cta {
    p.small {
      font-size: 2.5rem;
      line-height: 2.5rem;
    }

    p.large {
      font-size: 4.5rem;
      line-height: 4.5rem;
    }
  }
}
